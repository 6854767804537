<template>
  <VotePage_2 :settings="settings"/>
</template>

<script>
import VotePage_2 from "@/pages/Custom/VotePage_2.vue";
import storage from "@/services/storage";

export default {
  name: 'VotesPage',
  components: {VotePage_2},
  data() {
    return {
      settings: null,
    }
  },
  mounted() {
    this.settings = storage.get('settings')
  }
}
</script>
