import {reactive} from "vue";
import {io} from "socket.io-client";

export const state = reactive({
    connected: false,
    fooEvents: [],
    barEvents: []
});

const URL = await process.env.VUE_APP_SOCKET_URL;


export const socket = io(URL, {
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 1,
    secure: true,
    transports: ['websocket']
});

socket.on("connect", () => {
    state.connected = true;
});

socket.on('disconnect', () => {
    state.connected = false;
});
socket.on("reconnect", () => {
    state.connected = true;

});
